import React from "react";
import { Link } from "gatsby";
import {
    ArticleItem,
    ArticleItemContent, ArticleItemCreatedAt,
    ArticleItemDescription,
    ArticleItemTitle, ArticlesListInner, ArticlesListWrapper, BlogArticlesWrapper,
} from "./styles";
import { HOST_IMAGE_URL } from "../../constants";
import { format, parseISO } from "date-fns";
import Typography from "@mui/material/Typography";
import ArrowRight from "../../assets/icons/ArrowRight";
import palette from "../../theme/palette";
import { BlogButton } from "../../styles/blog";

const BlogRecentArticles = ({ recent }) => {
    const initialPostList = 3;
    const incrementInitialPostList = recent.data.length;

    const [displayPosts, setDisplayPosts] = React.useState(initialPostList);

    const handleLoadMore = () => {
        setDisplayPosts(displayPosts + incrementInitialPostList)
    }

    return (
        <BlogArticlesWrapper>
            <Typography variant="h3">Recent articles</Typography>
            <ArticlesListWrapper>
                <ArticlesListInner>
                    {recent?.data?.slice(0, 3)?.map((item) => {
                        return (
                            <ArticleItem key={item.id} className="recentItem">
                                <Link to={`/blog/${item?.attributes?.slug}`} className="image">
                                    <img
                                        src={`${
                                            HOST_IMAGE_URL +
                                            item?.attributes?.image?.data?.attributes?.url
                                        }`}
                                        alt={
                                            item?.attributes?.image?.data?.attributes?.alternativeText
                                        }
                                        title={item?.attributes?.image?.data?.attributes?.caption}
                                    />
                                </Link>
                                <ArticleItemContent className="content">
                                    <ArticleItemTitle to={`/blog/${item?.attributes?.slug}`}>
                                        <Typography variant="h4" className="text">
                                            {item?.attributes?.title}
                                        </Typography>
                                    </ArticleItemTitle>
                                    <ArticleItemDescription
                                        variant="subtitle2"
                                        component="div"
                                        className="description"
                                        dangerouslySetInnerHTML={{
                                            __html: item?.attributes?.description,
                                        }}
                                    />
                                    <ArticleItemCreatedAt variant="subtitle2" component="div">
                                        {format(parseISO(item?.attributes?.createdAt), 'dd MMMM yyyy')}
                                    </ArticleItemCreatedAt>
                                </ArticleItemContent>
                            </ArticleItem>
                        )
                    })}
                </ArticlesListInner>
                {displayPosts < recent.data.length ? (
                        <BlogButton
                            type="button"
                            endIcon={<ArrowRight color={palette.secondary} />}
                            onClick={handleLoadMore}
                        >
                            Load more Articles
                        </BlogButton>
                    ) :
                    <ArticlesListInner>
                        {recent?.data?.slice(3, displayPosts)?.map((item) => {
                            return (
                                <ArticleItem key={item.id}>
                                    <Link to={`/blog/${item?.attributes?.slug}`} className="image">
                                        <img
                                            src={`${
                                                HOST_IMAGE_URL +
                                                item?.attributes?.image?.data?.attributes?.url
                                            }`}
                                            alt={
                                                item?.attributes?.image?.data?.attributes?.alternativeText
                                            }
                                            title={item?.attributes?.image?.data?.attributes?.caption}
                                        />
                                    </Link>
                                    <ArticleItemContent className="content">
                                        <ArticleItemTitle to={`/blog/${item?.attributes?.slug}`}>
                                            <Typography variant="h4" className="text">
                                                {item?.attributes?.title}
                                            </Typography>
                                        </ArticleItemTitle>
                                        <ArticleItemDescription
                                            variant="subtitle2"
                                            component="div"
                                            className="description"
                                            dangerouslySetInnerHTML={{
                                                __html: item?.attributes?.description,
                                            }}
                                        />
                                        <ArticleItemCreatedAt variant="subtitle2" component="div">
                                            {format(parseISO(item?.attributes?.createdAt), 'dd MMMM yyyy')}
                                        </ArticleItemCreatedAt>
                                    </ArticleItemContent>
                                </ArticleItem>
                            )
                        })}
                    </ArticlesListInner>
                }
            </ArticlesListWrapper>
        </BlogArticlesWrapper>
    );
};

export default BlogRecentArticles;
