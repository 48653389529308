import {
    BlogFAQList, BlogFAQListAnswerTitle,
    BlogFAQListItem,
    BlogFAQListQuestionArrow, BlogFAQListQuestionTitle,
    BlogFAQWrapper
} from "./styles";
import React, { useState } from "react";
import ArrowRight from "../../assets/icons/ArrowRight";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";

const BlogFAQ = ({ faqList }) => {
  const [expanded, setExpanded] = useState(faqList[0].id);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
      <BlogFAQWrapper>
        <Typography variant="h3">FAQ</Typography>
        <BlogFAQList>
          {faqList?.map((item) => {
            return (
                <BlogFAQListItem
                    key={item.id}
                    expanded={expanded === item.id}
                    onChange={handleChange(item.id)}
                    className={expanded === item.id ? "expanded" : ""}
                >
                  <AccordionSummary
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      expandIcon={<BlogFAQListQuestionArrow className={expanded === item.id ? "expanded" : ""}>
                        <ArrowRight />
                      </BlogFAQListQuestionArrow>}
                  >
                    <BlogFAQListQuestionTitle variant="h4">{item.question}</BlogFAQListQuestionTitle>
                  </AccordionSummary>
                  <AccordionDetails>
                      <BlogFAQListAnswerTitle variant="body2">{item.answer}</BlogFAQListAnswerTitle>
                  </AccordionDetails>
                </BlogFAQListItem>
            )
          })}
        </BlogFAQList>
      </BlogFAQWrapper>
  );
};

export default BlogFAQ;
