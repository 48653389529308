import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import palette from "../../theme/palette";
import Typography from "@mui/material/Typography";
import theme from "../../theme";

export const BlogFAQWrapper = styled(Box)({
    marginTop: "60px",
});

export const BlogFAQList = styled(Box)({
    marginTop: "20px",
});

export const BlogFAQListItem = styled(Accordion)({
    marginTop: "20px",
    padding: "24px 16px",
    backgroundColor: "transparent",
    border: `1px solid ${palette.blueChalk}`,
    boxShadow: "none",

    "&:before": {
        display: "none",
    },

    "&:first-of-type": {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
    },

    "& .MuiAccordionSummary-root": {
        padding: 0,

        "& .MuiAccordionSummary-content": {
            overflow: "hidden",
            display: "-webkit-box",
            margin: 0,
            paddingRight: "24px",
            WebkitBoxOrient: "vertical",
            WebkitLineClamp: 2,
        },
    },

    "& .MuiAccordionDetails-root": {
        marginTop: "12px",
        padding: 0,
    },

    "&.expanded": {
        color: palette.secondary,
        backgroundColor: palette.purpleBlue,
    },

    [theme.breakpoints.down("sm")]: {
        "& .MuiAccordionSummary-root": {
            "& .MuiAccordionSummary-content": {
                paddingRight: "12px",
            },
        }
    },
});

export const BlogFAQListQuestionArrow = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    width: '52px',
    height: '52px',
    border: `1px solid ${palette.primary}`,
    borderRadius: '100px',

    "& svg": {
        transform: "rotate(90deg)",

        "& path": {
            fill: palette.primary,
        },
    },

    "&.expanded": {
        border: `1px solid ${palette.secondary}`,

        "& svg": {
            transform: "rotate(-270deg)",

            "& path": {
                fill: palette.secondary,
            },
        },
    },
});

export const BlogFAQListQuestionTitle = styled(Typography)({
    [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        fontWeight: 700,
        lineHeight: "22px",
        letterSpacing: "0.44px",
    }
});

export const BlogFAQListAnswerTitle = styled(Typography)({
    [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "22px",
    }
});
