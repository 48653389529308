import React from "react";
import { graphql } from "gatsby";
import Typography from "@mui/material/Typography";
import Layout from "../layout";
import Seo from "../components/seo";
import Container from "../components/container";
import {
    BlogBackground,
    BlogTop,
    BlogTopDescription,
    BlogTopInner
} from "../styles/blog";
import BlogBackgroundImage from "../assets/images/blog-background.svg";
import RecentArticlesList from "../components/articlesList/BlogRecentArticles";
import DefaultArticlesList from "../components/articlesList/BlogRecommendedPosts";
import BlogFAQ from "../components/blogFAQ";
import BlogRecommendedPosts from "../components/articlesList/BlogRecommendedPosts";
import BlogRecentArticles from "../components/articlesList/BlogRecentArticles";

function Blog({
                  data: {
                      strapi: { blog, articles, recent }
                  }
}) {
    return (
        <Layout>
            <Seo
                title={blog?.data?.attributes?.seo?.metaTitle}
                description={blog?.data?.attributes?.seo?.metaDescription}
                image={blog?.data?.attributes?.seo?.metaImage?.data?.attributes?.url}
            />
            <BlogTop>
                <BlogBackground><BlogBackgroundImage /></BlogBackground>
                <Container direction="column">
                    <BlogTopInner>
                        <Typography variant="h1">{blog?.data?.attributes?.title}</Typography>
                        <BlogTopDescription
                            variant="body1"
                            dangerouslySetInnerHTML={{
                                __html: blog?.data?.attributes?.description,
                            }}
                        />
                    </BlogTopInner>
                </Container>
            </BlogTop>
            <Container direction="column">
                {/* Blog Recent Articles */}
                <BlogRecentArticles recent={recent} />
                {/* Blog Recommended Posts */}
                <BlogRecommendedPosts articles={articles} title="Recommended Posts" />
                {/* Blog FAQ */}
                <BlogFAQ faqList={blog?.data?.attributes?.faqList} />
            </Container>
        </Layout>
    );
}

export default Blog;

export const query = graphql`
  query {
    strapi {
      blog {
        data {
          attributes {
            seo {
              ...seo
            }
            title
            description
            faqList {
                id
                question
                answer
            }
          }
        }
      }
      articles(sort: ["createdAt:desc"]) {
        data {
            id
            attributes {
                slug
                title
                description
                image {
                    data {
                        attributes {
                            url
                            alternativeText
                            caption
                        }
                    }
                }
                createdAt
            }
        }
      }
      recent: articles(sort: ["sort:desc", "createdAt:desc"]) {
        data {
            id
            attributes {
                slug
                title
                description
                image {
                    data {
                        attributes {
                            url
                            alternativeText
                            caption
                        }
                    }
                }
                createdAt
            }
        }
      }
    }
  }
`;